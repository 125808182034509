@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* 圆头 button */
    .btn-rounded-full {
        @apply bg-accent text-white font-bold py-2 px-4 rounded-full min-w-max;
    }

    /* 弧角 button */
    .btn-rounded-corner {
        @apply bg-accent text-white font-bold py-2 px-4 rounded-lg min-w-max;
    }

    /* 渐变 button:hover */
    .btn-rounded-gradient:hover {
        @apply border-[#00000000];
        @apply bg-origin-border bg-acc-hlt-gradient;
    }

    /* 渐变 bg */
    .bg-acc-hlt-gradient {
        background: linear-gradient(245deg, #ffadde 3.13%, #8995ff 108.41%);
    }

    /* 半透明卡片 */
    .card-transluscent {
        @apply rounded-3xl bg-transluscent animate-showing;
        /* @apply dark:bg-transluscent-dark; */
        border: 2px solid rgba(255, 255, 255, 0.3);
    }
/* 
    @media (prefers-color-scheme: dark) {
        .card-transluscent {
            @apply rounded-3xl bg-transluscent-dark animate-showing;
            border: 2px solid rgba(3, 9, 68, 0.3);
        }
    } */

    .card-transluscent-active {
        @apply card-transluscent;
        box-shadow: -5px 5px 25px rgba(0, 0, 0, 0.3);
    }

    /* 半透明按钮 */
    .btn-transluscent {
        @apply bg-transluscent p-4 max-h-min max-w-min rounded-2xl;
    }

    /* 半透明背景，private scope，直接用上面两个就好 */
    .bg-transluscent {
        @apply backdrop-blur-sm;
        background: radial-gradient(
            90% 130% at 0% -20%,
            rgba(255, 255, 255, 0.25),
            rgba(255, 255, 255, 0.05)
        );
        background-clip: padding-box;
        box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1);
    }

    .bg-logo-gradient {
        @apply absolute bg-clip-text text-transparent;
        background: conic-gradient(
            from 90deg at 50.68% 49.03%,
            #c6e4fc 0deg,
            #dbdbfc 123.75deg,
            #ffdce8 256.87deg,
            #f5efc7 360deg
        );
    }
}

@layer utilities {
    .card-transluscent-hover {
        @apply card-transluscent-active;
    }

    .bg-transluscent-dark {
        @apply backdrop-blur-sm;
        background: radial-gradient(
            90% 130% at 0% -20%,
            rgba(8, 12, 48, 0.1),
            rgba(8, 12, 48, 0.05)
        );
        background-clip: padding-box;
        box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1);
    }
}
