.bg-gradient {
    filter: blur(1px);
    background: radial-gradient(
            75% 75% at 90vw 10vh,
            rgba(187, 216, 239, 1),
            rgba(187, 216, 239, 0) 75%
        ),
        radial-gradient(
            farthest-corner at 90vw 90vh,
            rgba(249, 245, 220, 1),
            rgba(249, 245, 220, 0) 75%
        ),
        radial-gradient(
            farthest-corner at 10vw 90vh,
            rgba(255, 220, 232, 1),
            rgba(255, 220, 232, 0) 75%
        ),
        radial-gradient(
            farthest-corner at 10vw 10vh,
            rgba(219, 219, 252, 1),
            rgba(219, 219, 252, 0) 75%
        ),
        url('../../../assets/noise.svg');
}

/* @media (prefers-color-scheme: dark) {
    .bg-gradient {
        filter: blur(1px);
        background: radial-gradient(
                75% 75% at 90vw 10vh,
                rgba(46, 67, 84, 1),
                rgba(46, 67, 84, 0) 75%
            ),
            radial-gradient(
                farthest-corner at 90vw 90vh,
                rgba(78, 74, 48, 1),
                rgba(78, 74, 48, 0) 75%
            ),
            radial-gradient(
                farthest-corner at 10vw 90vh,
                rgba(95, 55, 69, 1),
                rgba(95, 55, 69, 0) 75%
            ),
            radial-gradient(
                farthest-corner at 10vw 10vh,
                rgba(55, 55, 89, 1),
                rgba(55, 55, 89, 0) 75%
            ),
            url('../../../assets/noise.svg'), #000000;
    }
} */
